import React, { Component } from 'react';
import './Footer.css';
import { Constants, defaultServices } from '../e_services/Constants';

const Footer = () => {
    return (
        <div class="container-fluid bg-dark text-body footer mt-5 pt-5 px-0 wow fadeIn" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-lg-3 col-md-6">
                        <h3 class="text-light mb-4">Address</h3>
                        <p class="mb-2"><i class="fa fa-map-marker-alt text-primary me-3"></i>No 01, Route 1, Group 20, Village 5A, Trang Dai Ward, Bien Hoa City, Dong Nai Province, Viet Nam - 810000</p>
                        <p class="mb-2"><i class="fa fa-phone-alt text-primary me-3"></i>{Constants.Phone}</p>
                        <p class="mb-2"><i class="fa fa-envelope text-primary me-3"></i>maytrethaibinh@gmail.com</p>
                        <div class="d-flex pt-2">
                            <a class="btn btn-square btn-outline-body me-1" href=""><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-square btn-outline-body me-1" href=""><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-square btn-outline-body me-1" href=""><i class="fab fa-youtube"></i></a>
                            <a class="btn btn-square btn-outline-body me-0" href=""><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h3 class="text-light mb-4">Services</h3>
                        {defaultServices.map((k, v) => {
                            return <a class="btn btn-link" href="/services">{k.name}</a>
                        })}
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h3 class="text-light mb-4">Quick Links</h3>
                        <a class="btn btn-link" href="/about">About Us</a>
                        <a class="btn btn-link" href="/contact">Contact Us</a>
                        <a class="btn btn-link" href="/services">Our Services</a>
                        <a class="btn btn-link" href="/terms">Terms & Condition</a>
                        <a class="btn btn-link" href="/support">Support</a>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h3 class="text-light mb-4">Newsletter</h3>
                        <p>Sign up to receive product update and special offers from us.</p>
                        <div class="position-relative mx-auto" style={{ 'maxWidth': '400px' }}>
                            <input class="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                            <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a href="/">MAY TRE THAI BINH</a>, All Right Reserved.
                        </div>
                        <div class="col-md-6 text-center text-md-end">
                            Designed By Phuong Hoang
                            <br /> Distributed By: <a class="border-bottom" href="/" target="_blank">May tre Thai Binh</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-js-waiting" style={{ "display": "none", "width": "0", "height": "0" }} />
        </div>
    )
}

export default Footer;