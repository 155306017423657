import React, { Component, useEffect, useState } from 'react';
import ProductItem from '../c_components/ProductItem';
import api from '../d_api/api';
import { useCallback } from 'react';
import { Loading } from '../c_components/Loading';
import { Layout } from '../a_layout/Layout';
import { useQueryParams } from '../f_hooks/useQueryParams';
import { useHistory, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Constants } from '../e_services/Constants';

const Products = (props) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { page } = useQueryParams()

    const getData = useCallback(async (page) => {
        setIsLoading(true)

        try {
            const { data } = await api.get(`/api/products?page=${page || 1}`)
            setData(data)
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        getData(page)
    }, [getData, page])

    const renderBody = () => {
        const products = data.products
        let body = []
        for (let i = 0; i < products?.length ?? 0; i += 4) {
            body.push(
                <div class="row g-4">
                    <ProductItem item={products[i + 0]} />
                    <ProductItem item={products[i + 1]} />
                    <ProductItem item={products[i + 2]} />
                    <ProductItem item={products[i + 3]} />
                </div>)
        }

        return body
    }

    const navigate = useNavigate()
    const onChangePage = (i) => {
        navigate({
            pathname: '/products',
            search: `?page=${i}`,
        });
    }

    const renderPage = () => {
        if (!data?.pages) return

        let pages = []
        for (let i = 0; i < data.pages; i++) {
            pages.push(<button onClick={() => { onChangePage(i + 1) }} class={`btn ${i === page - 1 ? 'btn-secondary' : 'btn-primary'}`} style={{ marginRight: '5px' }}>{i + 1}</button>)
        }

        return pages
    }

    return (
        <>
            <Helmet>
                <title>{`${Constants.Title} - Products`}</title>
                <meta name="description" content={`Explore our collection of solid timber furniture with elegant rattan accents. An eco-conscious material, rattan provides the perfect finishing touches that tie your entire space together with a relaxed, fresh coastal atmosphere.`} />
                <link rel="canonical" href={`${window.location.href}`} />
            </Helmet>
            <div class="container-fluid page-header-2 py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div class="container py-5">
                    <h1 class="display-1 text-white animated slideInDown">Products</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol class="breadcrumb text-uppercase mb-0">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                            <li class="breadcrumb-item text-primary active" aria-current="page">Products</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
                        <h4 class="section-title">Our products</h4>
                        <p class="display-8">Explore our collection of solid timber furniture with elegant rattan accents. An eco-conscious material, rattan provides the perfect finishing touches that tie your entire space together with a relaxed, fresh coastal atmosphere.</p>
                    </div>
                    <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
                        {renderPage()}
                    </div>
                    {
                        isLoading ? <Loading /> : renderBody()
                    }
                </div>
            </div>
        </>
    );
}

export default Layout(Products);