import React, { Component } from 'react';
import WidgetAboutUs1 from '../c_components/WidgetAboutUs1';
import WidgetAboutUs2 from '../c_components/WidgetAboutUs2';
import WidgetTeam from '../c_components/WidgetTeam';
import { Layout } from '../a_layout/Layout';
import { Helmet } from 'react-helmet';
import { Constants } from '../e_services/Constants';

class About extends Component {
  static displayName = About.name;

  render() {
    return (
      <>
        <Helmet>
          <title>{Constants.Title} - About us</title>
          <meta name="description" content={`About us`} />
          <link rel="canonical" href={`${window.location.href}`} />
        </Helmet>
        <div class="container-fluid page-header-1 py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
          <div class="container py-5">
            <h1 class="display-1 text-white animated slideInDown">About Us</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol class="breadcrumb text-uppercase mb-0">
                <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                <li class="breadcrumb-item text-primary active" aria-current="page">About</li>
              </ol>
            </nav>
          </div>
        </div>
        <WidgetAboutUs1 />
        <WidgetAboutUs2 />
        <WidgetTeam />
      </>
    );
  }
}

export default Layout(About);
