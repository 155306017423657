import React, { Component } from 'react';
import { Constants } from '../e_services/Constants';
import { Layout } from '../a_layout/Layout';
import { Helmet } from 'react-helmet';

const Term = () => {
  return (
    <>
      <Helmet>
        <title>{Constants.Title} - Term and use</title>
        <meta name="description" content={`Term and use`} />
        <link rel="canonical" href={`${window.location.href}`} />
      </Helmet>
      <div class="container-fluid page-header-4 py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
          <h1 class="display-1 text-white animated slideInDown">Term and use</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb text-uppercase mb-0">
              <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
              <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
              <li class="breadcrumb-item text-primary active" aria-current="page">Term and use</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Term and use</h4>
          </div>
          <div class="row g-5">
            <div class="text-left wow fadeInUp" data-wow-delay="0.5s">
              <p class="mb-4">
                Welcome to {Constants.Site}. These terms of use govern your use of our website; by using our website, you accept these terms in full. If you disagree with these terms of use or any part of these terms, you must not use our website.
              </p>
              <p class="mb-4">
                You must be at least 18 years of age to use our website. By using our website and by agreeing to these terms of use, you warrant and represent that you are at least 18 years of age.
              </p>
              <p class="mb-4">
                {Constants.Site} uses cookies. By using our website and agreeing to these terms of use, you consent to our use of cookies in accordance with the terms of {Constants.Site}'s privacy policy.
              </p>
              <p class="mb-4">
                <strong>License to use website</strong>
              </p>
              <p class="mb-4">
                Unless otherwise stated, {Constants.Site} and/or its licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.
              </p>
              <p class="mb-4">
                You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms of use.
              </p>
              <p class="mb-4">
                <strong>You must not:</strong>
              </p>
              <p class="mb-4">
                <i class="fa fa-check"></i> Republish material from this website (including republication on another website);<br />
                <i class="fa fa-check"></i> Sell, rent or sub-license material from the website;<br />
                <i class="fa fa-check"></i> Show any material from the website in public;<br />
                <i class="fa fa-check"></i> Reproduce, duplicate, copy or otherwise exploit material on our website for a commercial purpose;<br />
                <i class="fa fa-check"></i> Edit or otherwise modify any material on the website; or<br />
                <i class="fa fa-check"></i> Redistribute material from this website [except for content specifically and expressly made available for redistribution.<br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout(Term);