import React, { Component, useEffect } from 'react';
import WidgetSlider from '../c_components/WidgetSlider';
import WidgetAboutUs1 from '../c_components/WidgetAboutUs1';
import WidgetAboutUs2 from '../c_components/WidgetAboutUs2';
import WidgetTeam from '../c_components/WidgetTeam';
import WidgetProjects from '../c_components/WidgetProjects';
import WidgetAppointment from '../c_components/WidgetAppointment';
import WidgetTestimonial from '../c_components/WidgetTestimonial';
import WidgetServices2 from '../c_components/WidgetServices2';
import WidgetServices1 from '../c_components/WidgetServices1';
import { Layout } from '../a_layout/Layout';
import { Helmet } from 'react-helmet';
import { Constants } from '../e_services/Constants';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>{Constants.Title}</title>        
        <meta name="description" content={Constants.Description} />
        <link rel="canonical" href={`${Constants.Site}`} />
      </Helmet>
      <WidgetSlider />
      <WidgetServices1 />
      <WidgetAboutUs1 />
      <WidgetServices2 />
      <WidgetAboutUs2 />
      <WidgetProjects />
      <WidgetTeam />
      <WidgetAppointment />
      <WidgetTestimonial />
    </>
  );
}

export default Layout(Home);