const Constants = {
  Phone: "+84 933.498.580",
  Mail: "maytrethaibinh@gmail.com",
  Address: "No 01, Route 1, Group 20, Village 5A, Trang Dai Ward, Bien Hoa City, Dong Nai Province, Viet Nam - 810000",
  Site: "https://www.maytrethaibinh.com/",
  Title: "Công ty TNHH Mây Tre Thái Bình (www.maytrethaibinh.com)",
  Description: "A Creative Rattan Furniture For Your Dream Home. We are a manufacturing company in rattan materials, rattan decorative products and rattan furniture. We are suppling the variety of high-quality rattan product and providing a number of services in rattan weaving, Danish paper cord weaving, Linen webbing weaving, leather webbing weaving. We were established in 2019 to balance the huge demand from customer for rattan product and weaving services. We are committed to bring our high-quality rattan material, rattan decorative products and rattan furniture to the world in the sustainable and environmental ways. In the situation of the shortage in rattan material that cause price increasing, we are come up with the best solution to build new product design, new production innovation to harmonize the demand and supply for our valued customer. We are looking for good cooperation, long-term business and new development from our valued customers. We would like have a contribution to your business growth and development in the near future."
}

const imageReviewStyle = {
  width: '100%',
  height: 'auto',
  border: 'solid 1px'
}

const imageReviewIconRemoveStyle = {
  marginLeft: '-15px',
  marginTop: '3px',
  cursor: 'pointer',
}

const imageReviewContainerStyle = {
  display: 'inline-flex',
  position: 'relative',
  width: '100px',
  height: '100px',
  marginRight: '5px'
}

const productProperties = [
  'Dimensions',
  'Weight',
  'Weight Capacity per Drawer',
  'Internal Drawer Dimensions',
  'Leg Height',
  'Require Assembly',
  'Material',
  'SKU'
]

const productDefaultProperties = [
  'H113.5cm x W48cm x D42cm',
  '25kg',
  '10kg',
  'H12cm X W40CM x D29cm',
  '12cm',
  'YES',
  'Engineered wood board with wood effect finish and rattan effect drawer detail',
  '20240222'
]

const defaultServices = [
  {
    name: 'Rattan Furniture Sales',
    desc: 'Sell a variety of rattan furniture pieces, including chairs, tables, sofas, and outdoor furniture sets.',
    img1: 'img/service-1.jpg',
    img2: 'img/icons/icon-5.png',
  },
  {
    name: 'Custom Rattan Furniture Design',
    desc: 'Offer bespoke rattan furniture design services tailored to customer\'s preferences and requirements.',
    img1: 'img/service-2.jpg',
    img2: 'img/icons/icon-6.png',
  },
  {
    name: 'Rattan Furniture Repair and Restoration',
    desc: 'Provide repair services for damaged rattan furniture, including reweaving, replacing broken parts, and refinishing.Provide repair services for damaged rattan furniture, including reweaving, replacing broken parts, and refinishing.',
    img1: 'img/service-3.jpg',
    img2: 'img/icons/icon-7.png',
  },
  {
    name: 'Rattan Furniture Maintenance',
    desc: 'Provide maintenance services to prolong the lifespan of rattan furniture, including cleaning, conditioning, and preventive repairs.',
    img1: 'img/service-4.jpg',
    img2: 'img/icons/icon-8.png',
  },
  {
    name: 'Rattan Furniture Rental',
    desc: 'Offer rental services for events, photo shoots, or temporary use, providing a variety of rattan furniture options for customers to choose from.',
    img1: 'img/service-5.jpg',
    img2: 'img/icons/icon-9.png',
  },
  {
    name: 'Rattan Furniture Accessories',
    desc: 'Sell accessories such as cushions, pillows, and covers designed specifically for rattan furniture pieces.',
    img1: 'img/service-6.jpg',
    img2: 'img/icons/icon-10.png',
  }
]

export {
  Constants
  , imageReviewContainerStyle
  , imageReviewStyle
  , imageReviewIconRemoveStyle
  , productProperties
  , productDefaultProperties
  , defaultServices
}
